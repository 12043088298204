@import '../../common/variables';
@import '../../common/mixins';

.categoriesContainer {
  max-width: 1056px;
  margin: 24px auto 24px auto;
  padding: 0 16px;
  font-size: 18px;
}

.categories {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.category {
  flex: 25% 0 0;
  margin-bottom: 2px;

  @include for-desktop-down {
    flex: 50% 0 0;
  }

  @include for-smartphone {
    flex: 100% 0 0;

    &.noMobile {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: $kibu-blue;

    &:hover {
      color: $kibu-magenta;
    }
  }
  // text-transform: ;
}