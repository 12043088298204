@import "../../common/mixins";

.footerSocial {
    padding: 16px 0;
    max-width: 960px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include for-smartphone {
        display: block;
        padding: 16px 16px;
    }
}

.footerSocialBox {
    flex: 33.3% 0 0;
    text-align: center;
    font-size: 36px;
    color: #fff;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include for-smartphone {
        display: block;
        width: 100%;
    }

    > img {
        text-align: center;
    }
}

.footerSocialBoxIconsContainer {
    justify-content: center;

    @include for-smartphone {
        display: flex;
        margin-top: 16px;
    }
}

.footerSocialBox .socialIcon {
    width: 48px;
    height: 48px;
    padding-top: 6px;
    background-color: #fff;
    border-radius: 8px;

   &:first-child {
    margin-right: 16px;
   } 
}

