@import "../../common/mixins";

.communityAdvContainer {
    max-width: 400px;
    margin: 0 auto;
    padding: 16px 0 0 0;
    align-items: stretch;
}

.communityAdvImage {
    background-color: #fff;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
}

.communityAdvImage img {
    width: 100%;
}

.communityAdvText {
    font-size: 16px;
    text-align: center;

    a {
        text-decoration: none;
    }
}

.communityAdvText .firstLine {
    font-weight: bold;
    color: #666;
}

.communityAdvText .secondLine {
    font-weight: 300;
    color: #666;
    text-transform: uppercase;
}