@import "../../common/variables";
@import "../../common/mixins";

.articleListingItem {
  width: calc(33% - 16px);
  text-decoration: none;
  padding-bottom: 16px;

  @include for-desktop-down {
    width: calc(50% - 16px);
  }

  @include for-smartphone {
    width: 100%;
  }

  .articleListingItemImage {
    width: 100%;
    height: 300px;
    background: no-repeat center center / cover;
    border-radius: 8px;
  }

  .articleListingItemTitle {
    margin-top: 4px;
    font-size: 20px;
    line-height: 26px;
    color: $kibu-magenta;

    &.articleListingItemTitleEmpty {
      font-style: italic;
    }
  }

  .articleListingItemDate {
    position: relative;
    font-size: 13px;
    color: #999;
    padding-top: 4px;

    .articleListingItemTags {
      position: absolute;
      right: 0;
      top: 0;

      .articleListingItemTag {
        display: inline-block;
        border-radius: 4px;
        border: 1px solid $kibu-blue;
        color: $kibu-blue;
        background-color: #e6f7fd;
        padding: 2px;
        margin-right: 4px;
        line-height: 17px;
      }
    }
  }

  &:hover .articleListingItemTitle, &:hover .articleListingItemImage {
    opacity: 0.7;
  }
}