@import "../../common/mixins";

.articlesListingPageContainer {
  max-width: 1056px;
  margin: 16px auto 16px auto;

  @include for-desktop-down {
    padding: 0 16px;
  }
}

.articlesListingContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}