@import "../../common/variables";

.categoryContentContainer {
  margin-bottom: 16px;
  font-size: 14px;
  color: $font-color-light;

  & div::first-letter {
    text-transform: uppercase;
  }

  h1, h2, h3, h4, h5 {
    line-height: normal;
    margin-bottom: 8px;
    color: $font-color;

    &::first-letter {
      text-transform: uppercase;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .wikiLinkContainer {
    text-align: right;

    .wikiLink {
      text-decoration: none;
      color: $kibu-blue;
    }
  }
}