@mixin for-desktop-xl-up {
  @media (min-width: 1280px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 991px) { @content; }
}

@mixin for-desktop-down {
  @media (max-width: 992px) { @content; }
}

@mixin for-smartphone {
  @media (max-width: 600px) { @content; }
}

@mixin extra-small {
  @media (max-width: 390px) { @content; }
}