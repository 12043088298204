@import "../../common/variables";

.share {
  border-top: 1px solid $kibu-blue;
  margin-top: 8px;
  padding-top: 16px;
  text-align: right;

  .shareTitle {
    padding-bottom: 16px;
    font-size: 24px;
  }

  .shareButton {
    display: inline-block;
    padding-left: 8px;
  }
}