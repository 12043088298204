@import "../../common/mixins";
@import "../../common/variables";

.toyExternalContainer {
    max-width: 1056px;
    margin: 0 auto;

    @include for-desktop-down {
        padding: 0 16px;
    }
}

.toyContainer {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    margin-bottom: 16px;

    @include for-desktop-down {
        display: block;
    }
}

.toyImage {
    flex: 50% 0 0;
    text-align: center;

    .toyTitleMobile {
        display: none;
        font-size: 24px;
        text-align: left;
        padding: 0 16px 16px 16px;

        @include for-desktop-down {
            display: block;
        }
    }

    @include for-desktop-down {
        flex: 40% 0 0;
        text-align: center;
        padding-top: 16px;
    }

    .toyImageClickToZoom {
        cursor: zoom-in;
        margin-top: 4px;
        font-size: 11px;
        color: $font-color-light;

        @include for-desktop-down {
            margin-top: -4px;
        }
    }

    img {
        cursor: zoom-in;
    }
}



.toyImage img {
    border-top-left-radius: 8px;
    max-width: 100%;

    @include for-desktop-down {
        max-height: 400px;
        border-radius: 8px;
    }
}

.toyDescription {
    flex: 50% 0 0;
    padding: 16px;

    @include for-desktop-down {
        flex: 60% 0 0;
    }
}

.toyTitle {
    font-size: 28px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #999;

    @include for-desktop-down {
        display: none;
    }
}

.toyText {
    font-weight: lighter;
    color: #444;
    word-wrap: break-word;
    white-space: pre-line;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: bold;
    }
    // p {margin: 0}

    h1 {font-size: 20px;}
    h2 {font-size: 18px;}
    h3 {font-size: 16px;}
    h4 {font-size: 14px;}
    h5 {font-size: 14px;}
    h6 {font-size: 14px;}
}
