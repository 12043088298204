@import '../../common/variables.scss';
@import '../../common/mixins.scss';

.listingContentContainer {
    max-width: 1056px;
    margin: 16px auto 32px auto;
    display: flex;

    @include for-desktop-down {
        display: block;
        margin-bottom: 16px;
    }

}

.listingContentSidebar {
    width: 20%;
    padding-right: 16px;

    @include for-desktop-down {
        width: auto;
        padding: 0;
    }
}

.listingSidebarTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: $font-color-light;

    .priceFilterReset {
        font-size: 14px;
        color: $kibu-magenta;
        cursor: pointer;
        line-height: 24px;
    }
}

.listingSidebarBox {
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 16px;

    .clearFilters {
        border-top: 1px solid $kibu-blue;
        margin-top: 16px;
        padding-top: 16px;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        color: $kibu-magenta;
    }

    @include for-desktop-down {
        display: none;

        &.listingSidebarBoxOpen {
            display: block;
            margin: 0 16px;
            border-top: 1px dashed #ccc;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}


.listingContentMain {
    width: 80%;
    padding-left: 16px;

    @include for-desktop-down {
        width: auto;
        margin-top: 16px;
    }

    @include extra-small {
        padding-right: 16px;
    }
}

.listingPaginationDescription {
    margin-bottom: 16px;
    color: $font-color-light;

    &.listingNoResults {
        text-align: center;
        padding-top: 32px;

        @include for-desktop-down {
            padding-right: 16px;
        }

        img {
            max-width: 100%;
        }
    }
}

.listingPagination {
    display: flex;
    padding: 8px;
    background-color: #fff;
    justify-content: center;
}

.listingPaginationPage {
    padding: 8px 12px;
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
}

.listingPaginationPageActive {
    background-color: #666;
    color: #fff;
}
