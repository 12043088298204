@import "../../common/mixins";
@import "../../common/variables";

.galleryContainer {
    max-width: 1056px;
    margin: 16px auto 16px auto;

    @include for-desktop-down {
        padding: 0 16px;
    }
}

.galleryTitle {
    font-size: 20px;
    color: #666;
}

.slide {
    display: block;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.slideContainer {
    border-radius: 12px;
    overflow: hidden;
}

.slide .slideImg {
    width: auto;
    height: 240px;
    margin: 0 auto;
    background: center top no-repeat transparent;
    background-size: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .galleryContainer.titled & {
        height: 110px;

        @include for-desktop-xl-up {
            height: 80px;
        }
    }
}

.slide .slideText {
    width: auto;
    height: 110px;
    padding: 8px;
    margin: 0 auto;
    background-color: #fff;
    overflow: hidden;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;

    @include for-smartphone {
        width: auto;
        height: 135px;
        margin: 0
    }

    .galleryContainer.titled & {
        height: 125px;

        @include for-smartphone {
            height: 140px;
        }
    }

    .galleryContainer.titled & div {
        font-size: 13px;
        max-height: 112px;
        overflow: hidden;

        @include extra-small {
            max-height: 87px;
        }
    }

    .galleryContainer.titled & h5 {
        margin: 0 0 8px 0;
        padding-bottom: 8px;
        font-size: 15px;
        border-bottom: 1px solid $kibu-blue;
    }
}

.slide img {
    max-width: 100%;
    max-height: 100%;
}

.slick-next:before,
.slick-prev:before {
    font-size: 48px;
    color: #666;
}

.slick-arrow {
    width: 48px;
    height: 48px;
    z-index: 1;
}

.slick-prev {
    left: -35px;

    @include for-desktop-down {
        left: 0;
    }
}

.slick-next {
    right: -35px;

    @include for-desktop-down {
        right: 0;
    }
}

.slick-dots {
    bottom: -16px;
}

.slick-dots li {
    width: 12px;
    margin: 0 3px;
}

.slick-dots li button:before {
    font-size: 6px;
    width: 12px;
}

.slick-dots li button {
    padding: 0;
    width: 16px;
}