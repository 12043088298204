@import '../../common/variables';
@import '../../common/mixins';

.homeCategoriesContainer {
  max-width: 1056px;
  margin: 24px auto 24px auto;
  font-size: 18px;

  @include for-desktop-down {
    padding: 0 16px;
  }

  a {
    text-decoration: none;
    color: $kibu-blue;

    &:hover {
      color: $kibu-magenta;
    }
  }
}

.homeCategoriesTitle {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-content: center;

  h2 {
    font-size: 1.2em;
    color: #666;
    margin: 0;
    font-weight: normal;
  }

  a {
    display: block;
    line-height: 30px;
    height: 21px;
  }
}

.homeCategoriesList {
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.homeCategory {
  flex: 33.3% 0 0;
  margin-bottom: 2px;

  @include for-desktop-down {
    flex: 50% 0 0;
  }

  @include for-smartphone {
    flex: 100% 0 0;

    &.noMobile {
      display: none;
    }
  }
  // text-transform: ;
}
