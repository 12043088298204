@import "../../common/variables";
@import "../../common/mixins";

.contactContainer {
  max-width: 1056px;
  margin: 16px auto 32px auto;

  @include for-desktop-down {
    padding: 0 16px;
  }
}

.contactTitle {
  font-size: 24px;
  margin-bottom: 8px;
  font-weight: bold;
  color: $kibu-magenta;
}

.contactForm {
  background-color: #EEE;
  padding: 16px;
}

.contactFormField {
  display: flex;
  padding-bottom: 16px;
}

.contactFormLabel {
  width: 150px;
  text-align: right;
  padding-right: 8px;
  color: $kibu-magenta;
  font-weight: bold;
  padding-top: 6px;

  @include for-desktop-down {
    &-privacy {
      display: none;
    }
  }
}

.contactFormInput {
  flex-grow: 1;

  &-privacy {
    color: $kibu-magenta;

    @include for-desktop-down {
      text-align: justify;
    }

    a {
      color: inherit;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  input, select {
    max-width: 400px;
    width: 100%;
    line-height: 24px;
    height: 32px;
    font-family: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    outline: none !important;
    font-size: 16px;
    background-color: #fff;
  }

  input {
    padding: 0 8px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position-x: 99%;
    background-position-y: 5px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 0 2rem 0 6px;
  }

  textarea {
    max-width: 400px;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 8px;
    resize: none;
    height: 150px;
    font-family: inherit;
    font-size: 16px;
  }
}

.contactFormError {
  color: darkred;
}

.contactFormInputSend {
  width: 100px;
  line-height: 36px;
  text-align: center;
  background-color: #fff;
  color: $kibu-magenta;
  border: 1px solid $kibu-magenta;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}