.footerContainer {
    min-height: 100px;
    margin-top: 64px;
    padding-bottom: 16px;
    background: linear-gradient(90deg, rgba(59,162,255,1) 30%, rgba(242,87,162,1) 70%);
    background-size: contain;
}

.footer {
    max-width: 1056px;
    margin: 0 auto;
    padding: 16px 48px;
}

.footerBox:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    padding-bottom: 16px;
    margin-bottom: 16px;
}