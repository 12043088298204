@import "../common/mixins";
@import "../common/variables";

.articleContainer {
  max-width: 1056px;
  margin: 16px auto 16px auto;
  color: #666666;


  .articleTitle {
    font-size: 32px;
    color: $kibu-blue;
  }

  @include for-desktop-down {
    padding: 0 16px;

    .breadcrumbContainer {
      padding-left: 0;
    }
  }

  .articleContent {
    a {color: $kibu-magenta}
    a:hover {opacity: 0.7}
    table {border-collapse: collapse}
    th {text-align: left}
    th, td {padding: 4px 24px 4px 8px; border: 1px solid $articles-table-border}
    h2 {font-size: 28px}
  }

  .article-image-container {text-align: center}
  .article-image-container img:first-of-type {max-width: 640px; width: 100%;}

  .articleInfo {
    margin-top: 24px;
    color: $font-color-light;
    font-style: italic;
    text-align: right;
  }
}