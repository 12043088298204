@import "../../common/variables.scss";
@import "../../common/mixins.scss";

.filtersPrice {
    display: flex;
}

.mobileFiltersToggle {
    display: block;
    padding: 8px;
    margin: 0 16px;
    background-color: #fff;
    text-align: center;

    @include for-desktop-up {
        display: none;
    }

    svg {
        position: relative;
        margin-left: 3px;
        top: 2px;
    }

    .clearFilters {
        color: $kibu-magenta;
        font-size: 14px;
        line-height: 24px;
    }
}

.filtersPriceInputContainer {
    width: 42%;
    display: flex;
    border: 1px solid #999;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 8px;
}

.filtersPriceCurrency {
    font-size: 13px;
    line-height: 26px;
    color: $font-color-light;
}

.filtersPriceInput {
    padding-left: 2px;
}

.filtersPriceInputContainer input {
    width: 100%;
    border: 0;
    outline: none;
    line-height: 24px;
}

.filtersPriceInputSeparator {
    width: 16%;
    text-align: center;
}

.filtersPriceInputOk {
    text-align: center;
    color: #fff;
    background-color: $kibu-blue;
    border-radius: 8px;
    margin-top: 12px;
    padding: 4px 0;
    cursor: pointer;
}

.listingSidebarTitle:not(:first-child) {
    margin-top: 32px;
}

.filtersOptions {

}

.filtersOptionContainer {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $font-color-light;
    margin-bottom: 2px;
    cursor: pointer;

    &:hover, &:hover .check-icon {
        color: $kibu-blue !important;
    }
}

.filtersOptionContainer input {
    display: none;
}

.filtersOptionContainer .check-icon {
    margin-right: 6px;
    margin-top: 2px;
    flex: 0 0 24px;
}

.filtersOptionContainer .checked-icon {
    display: none;
}

.filtersOptionContainer input:checked ~ .checked-icon {
    display: inline-block;
}

.filtersOptionContainer input:checked ~ .unchecked-icon {
    display: none;
}

.filtersOptionContainerText {
    flex: 1 1 calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.filtersShowMoreMerchants {
    margin-top: 8px;
    text-align: center;
    color: $font-color-light;
    justify-content: center;
    cursor: pointer;
}

.price-slider {
    margin-top: 16px;
    margin-bottom: 24px;

    .range-slider__thumb {
        background-color: $kibu-blue;
    }

    .range-slider__range {
        background-color: $kibu-blue;
    }
}