@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

body * {
  box-sizing: border-box;
}
