@import "../common/mixins";

.aboutUsContainer {
  max-width: 800px;
  margin: 0 auto;
  color: #666666;


  h2 {
    font-size: 32px;
  }

  @include for-desktop-down {
    padding: 0 16px;

    .breadcrumbContainer {
      padding-left: 0;
    }
  }
}

.aboutUsText {
  display: flex;
  justify-content: center;

  @include for-desktop-down {
    flex-direction: column-reverse;
  }

  .aboutUsImage {
    margin-right: 16px;


    @include for-desktop-down {
      flex: 100% 0 0;
      text-align: center;
      margin: 32px 0 0;
    }

    img {
      max-width: 100%;
      @include  for-desktop-down {
        width: 60%;
      }
    }
  }

  .aboutUsInnerText {
    padding-top: 32px;
    margin-left: 16px;
    font-size: 28px;
    text-align: right;

    span {
      font-family: monospace, monospace;
    }

    @include for-desktop-down {
      margin: 0;
      flex: 100% 0 0;
      text-align: center;
      font-size: 22px;
    }
  }
}

.aboutUsMission {
  font-size: 24px;

  @include for-desktop-down {
    font-size: 22px;
  }
}