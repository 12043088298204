@import "../../common/mixins";

.homeClaimContainer {
    padding: 8px 0 16px 0;
    border-bottom: 1px solid #ccc;
    background-color: #fff;

    @include for-desktop-down {
        padding: 16px;
    }
}

.homeClaim {
    align-items: center;
    max-width: 550px;
    margin: 0 auto;
}

.homeClaimText {
    font-size: 16px;
    color: #666;
    padding-right: 16px;
    margin-bottom: 16px;
    text-align: center;

    @include for-desktop-down {
        font-size: 14px;
        text-align: center;
        padding: 0 16px;
    }
}

.homeClaimImage {
    padding: 0 24px;
}

.homeClaimImage img {
    width: 100%;
}