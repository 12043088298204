@import "../../common/mixins";

.breadcrumbContainer {
  max-width: 1056px;
  margin: 16px auto 32px auto;
  font-size: 14px;
  color: #666;

  @include for-desktop-down {
    padding-left: 16px;
  }
}

.breadcrumb:first-child, .breadcrumbClickable {
  .breadcrumbContent {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &:last-child, &:last-child:hover {
    .breadcrumbContent {
      cursor: default;
      opacity: 1;
    }
  }
}