@import "../../common/mixins";
@import "../../common/variables.scss";

.detailToyInfoContainer {
    max-width: 1056px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    margin: 0 auto 32px;

    @include for-desktop-down {
        display: block;
        margin-left: 16px;
        margin-right: 16px;
    }
}

.detailToyInfoStore {
    width: 450px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);

    @include for-desktop-down {
        width: auto;
    }
}

.detailToyInfoStoreLabel {
    font-size: 18px;
    color: #999;
}

.detailToyInfoStoreName {
    font-size: 26px;
    color: #666;
}

.detailToyInfoPrice {
    padding-left: 32px;
    margin-top: 8px;
    padding-top: 8px;
    padding-right: 32px;
    width: 300px;

    @include for-desktop-down {
        width: auto;
        display: flex;
        justify-content: space-between;
        padding: 16px;
        flex-wrap: wrap;
    }

    .detailToyInfoConversionDisclaimer {
        margin-bottom: 8px;
        font-size: 12px;
        color: $font-color-light;
        text-align: right;
    }
}

.detailToyInfoPriceLabel {
    font-size: 18px;
    color: #999;

    @include for-desktop-down {
        font-size: 24px;
        flex: 0 0 50%;
    }
}

.detailToyInfoPriceValue {
    text-align: right;
    font-size: 28px;
    color: $kibu-magenta;

    @include for-desktop-down {
        flex: 0 0 50%;
    }
}

.detailToyActions {
    text-align: center;
    flex-grow: 1;
    padding: 18px 32px 18px 32px;

    @include for-desktop-down {
        padding: 16px;
    }
}

.detailToyActionsButton {
    color: #fff;
    margin-bottom: 8px;
    border-radius: 12px;
    padding: 3px 0;

    &.in_stock {
        background-color: #1ec178;
    }
    &.pre_order {
        background-color: #3BA2FF;
    }
    &.not_available {
        background-color: #fd6161;
    }
    &.unknown {
        background-color: darkgray;
    }
}

.detailToyActionsStore {
    display: block;
    text-decoration: none;
    background-color: $kibu-magenta;
}