@import "../common/mixins.scss";
@import "../common/variables.scss";

.showMoreArticles {
    max-width: 1056px;
    margin: 16px auto 16px auto;
    text-align: right;

    @include for-desktop-down {
        padding: 0 16px;
    }

    a {
        text-decoration: none;
        color: $kibu-magenta;

        &:hover {
            opacity: 0.7;
        }
    }
}