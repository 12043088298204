@import "../../common/mixins";

.headerContainer {
    padding: 16px 0;
    margin-bottom: 7px;
    background-color: #fff;
    box-shadow: 0 0 7px 2px #ccc;

    @include for-desktop-down {
        padding-bottom: 8px;
    }
}

.header {
    max-width: 1056px;
    padding: 0 1rem;
    margin: 0 auto;
    display: flex;

    @include for-desktop-down {
        padding: 0 8px;
    }
}

.logo {
    display: flex;
    align-items: center;
    width: 33%;
    cursor: pointer;

    @include for-desktop-down {
        width: 77%;
    }
}

.logoText {
    padding-left: 8px;
    font-size: 28px;
    font-weight: 600;
}

.logoTextFirst {
    color: #3BA2FF;
}

.logoTextSecond {
    color: #F257A2;
}

.logoTextThird {
    color: #6d6d6d;
}

.search {
    width: 34%;
    text-align: center;
    position: relative;

    .searchOverButton {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 53px;
        height: 100%;
    }

    @include for-desktop-down {
        display: none;
    }

    &.searchMobile {
        display: none;

        @include for-desktop-down {
            display: block;
            margin-top: 8px;
            padding-left: 8px;
            padding-right: 8px;
            width: auto;
        }
    }
}

.search input {
    background: url(../../assets/zoom-lens.png) transparent no-repeat 12px 8px;
    background-size: 32px 32px;
    border-radius: 6px;
    border: 2px solid #ccc;
    padding: 16px 16px 16px 56px;
    font-size: 16px;
    width: 100%;
}

.search input:focus {
    border-color: #F257A2;
    outline: none;
}

.headContact {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 32px;
    width: 33%;

    @include for-desktop-down {
        padding-right: 8px;
    }
}

.headContactLogo {
    background: no-repeat #3BA2FF center center;
    border: 3px solid #F257A2;
    width: 32px;
    height: 32px;
    background-size: 16px 16px;
    border-radius: 32px;
    padding: 3px;
    margin-top: -4px;
}

.headContactText {
    padding-left: 8px;
    color: inherit;
    text-decoration: none;

    @include for-smartphone {
        display: none;
    }
}

.headContactSocialContainer {
    margin-left: 16px;
    display: flex;

    @include for-smartphone {
        margin-left: 8px;
    }

    @include extra-small {
        display: none;
    }
}

.headContactSocial:first-child {
    margin-right: 8px;
}

.advContainer {
    max-width: 1056px;

    img {
        max-width: 100%;
    }

    @include for-smartphone {
        display: block;
    }

    @include for-desktop-down {
        padding: 0 16px;
    }
}