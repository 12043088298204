@import "../../common/mixins";

.footerLegalContainer {
    max-width: 1056px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-smartphone {
        display: block;
    }
}

.footerLegalBox {
    text-align: center;
    flex: 33.3% 0 0;

    @include for-smartphone {
        flex: 100% 0 0;
    }
}

.footerLegalBoxLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;

    @include for-smartphone {
        margin-bottom: 16px;
    }
}

.footerLegalBoxLogo img {
    margin-right: 4px;
}

.footerLegalBoxLink a {
    color: #fff;
    text-decoration: none;
}

.footerLegaCopyright {
    font-size: 12px;
    color: #fff;

    @include for-smartphone {
        margin-top: 16px;
    }
}