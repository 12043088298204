@import "../../common/mixins";

.footerClaim {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;

    @include for-smartphone {
        display: block;
    }
}

.footerClaimElement {
    flex: 33.3% 0 0;
    text-align: center;
    color: #fff;
    width: 250px;

    @include for-smartphone {
        width: 100%;

        padding-bottom: 16px;
    }
}

.footerClaimElementTitle {
    font-size: 20px;
    font-weight: bold;
}

.footerClaimElementDescription {
    font-size: 16px;
}