@import "../../common/mixins";
@import "../../common/variables";

.listingItemContainer {
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    margin-bottom: 16px;
    align-items: stretch;
    min-height: 160px;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);
    }

    @include for-smartphone {
        flex-wrap: wrap;
    }


}

.listingItemImage {
    flex: 150px 0 0;
    min-height: 150px;

    text-align: center;
    background: center center no-repeat transparent;
    background-size: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include extra-small {
        flex: 0 0 100%;
        border-bottom-left-radius: 0;
        border-top-right-radius: 8px;

    }
}

.listingItemImage img {
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
}

.listingItemDescription {
    padding: 16px;
    flex: calc(100% - 310px) 0 0;

    @include for-smartphone {
        flex: calc(100% - 150px) 0 0;
    }

    @include extra-small {
        flex: 0 0 100%;
        padding: 16px 8px 0 8px;
    }
}

.listingItemTitle {
    font-size: 20px;

    @include for-smartphone {
        font-size: 1rem;
    }
}

.listingItemStore {
    @include for-smartphone {
        font-size: 1rem;
    }
}

.listingItemStoreLabel {
    color: #999;
}

.listingItemStoreName {
    font-weight: bold;
}

.listingItemAvailabilityPrice {
    display: flex;
    margin-top: 16px;
    justify-content: space-between;
    flex-wrap: wrap;
}

.listingItemAvailabilityLabel {
    flex: 0 0 auto;
    padding-top: 3px;

    @include for-desktop-down {
        display: none;
    }
}

.listingItemAvailabilityButton {
    flex: 1 1 auto;
    padding-left: 16px;

    button.available {
        color: white;
        width: 110px;
        border: 0;
        border-radius: 8px;
        padding: 6px 0;
        text-transform: capitalize;

        &.in_stock {
            background-color: #1ec178;
        }
        &.pre_order {
            background-color: #3BA2FF;
        }
        &.not_available {
            background-color: #fd6161;
        }
        &.unknown {
            background-color: darkgray;
        }
    }

    @include for-desktop-down {
        padding-left: 0;
    }

    @include for-smartphone {
        flex: 0 0 100%;
    }
}

.listingItemPrice {
    flex: 0 0 100px;
    text-align: right;
    margin-right: 32px;
    font-size: 24px;
    color: #F257A2;

    @include for-desktop-down {
        margin-right: 0;
    }

    @include for-smartphone {
        flex: 0 0 100%;
        text-align: left;
        margin-top: 8px;
    }
}

.listingItemActions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 16px;
    flex: 160px 0 0;
    margin-left: auto;

    @include for-smartphone {
        flex: 100% 0 0;
        flex-direction: row;
        padding: 16px 0;
    }
}


.listingItemActionsButton {
    font-size: 14px;
    color: white;
    background-color: $kibu-blue;
    padding: 8px 0;
    border-radius: 12px;
    width: 120px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

.listingItemActionsStore {
    font-size: 12px;
    background-color: #F257A2;

    @include for-smartphone {
        font-size: 1rem;
    }
}