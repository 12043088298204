@import "../../common/mixins";

.detailToyPrices {
  margin-top: 64px;
  padding-right: 32px;

  @include for-desktop-down {
    margin-top: 16px;
  }
}

.detailToyPricesCustomTooltip {
  outline: none;
}

.detailToyPricesTitle {
  color: #999;
  font-size: 21px;
  margin-bottom: 16px;
}